import Box from '@mui/material/Box';
import { UniversalAppStatus } from 'src/components/error/UniversalAppStatus';

function Custom404() {
  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100dvh - 190px)',
        mt: 24,
        mx: 16,
      }}
    >
      <UniversalAppStatus
        imgUrl="/assets/images/mascots/mascot-building.png"
        errorCode={404}
        size={400}
        title="PAGE NOT FOUND"
        description="Uh oh! Seems you have fallen into the void."
        prefetchImage={true}
      />
    </Box>
  );
}

export { Custom404 as default };
